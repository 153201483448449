import { useState, useEffect } from "react";
import useUserStore from "../lib/store/userStore";

const useAuthCheck = () => {
  const getUser = useUserStore.getState().getUser;
  const [isResearcherAuthenticated, setIsResearcherAuthenticated] =
    useState(false);
  const [isParticipantAuthenticated, setIsParticipantAuthenticated] =
    useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const checkAuth = async () => {
      try {
        const user = getUser();
        // console.log("here");
        // console.log(user);
        if (user !== null && user.role === "user") {
          setIsResearcherAuthenticated(true);
        } else {
          setIsResearcherAuthenticated(false);
        }
        if (user !== null && user.role === "participant") {
          setIsParticipantAuthenticated(true);
        } else {
          setIsParticipantAuthenticated(false);
        }
      } catch (error) {
        console.error("Auth check failed:", error);
        setIsResearcherAuthenticated(false);
        setIsParticipantAuthenticated(false);
      } finally {
        setIsLoading(false);
      }
    };

    checkAuth();
  }, []);

  return { isResearcherAuthenticated, isParticipantAuthenticated, isLoading };
};

export default useAuthCheck;
