// src/context/AuthContext.js
import React, { createContext, useContext, useState, useEffect } from "react";
import useAuthCheck from "../hook/useAuth";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const { isResearcherAuthenticated, isParticipantAuthenticated } =
    useAuthCheck();
  const [authState, setAuthState] = useState({
    isResearcherAuthenticated,
    isParticipantAuthenticated,
  });

  useEffect(() => {
    setAuthState({
      isResearcherAuthenticated,
      isParticipantAuthenticated,
    });
  }, [isResearcherAuthenticated, isParticipantAuthenticated]);

  return (
    <AuthContext.Provider value={{ authState, setAuthState }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
