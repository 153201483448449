// src/App.js
import "./App.css";
import React, { lazy, Suspense } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ProtectedRoute from "./lib/routes/ProtectedRoute";

const Home = lazy(() => import("./components/User/Home"));
const Login = lazy(() => import("./components/User/Login"));
const ResearcherFlow = lazy(() =>
  import("./components/Researcher/ResearcherFlow")
);
const ParticipantFlow = lazy(() =>
  import("./components/Participant/ParticipantFlow")
);
const ThankYou = lazy(() => import("./components/Participant/ThankYou"));

function App() {
  return (
    <BrowserRouter>
      <Suspense fallback={<div>Loading...</div>}>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route
            path="/instruction"
            element={
              <ProtectedRoute
                authCheck={({ isResearcherAuthenticated }) =>
                  isResearcherAuthenticated
                }
              >
                <ResearcherFlow />
              </ProtectedRoute>
            }
          />
          <Route
            path="/experiment"
            element={
              <ProtectedRoute
                authCheck={({ isParticipantAuthenticated }) =>
                  isParticipantAuthenticated
                }
              >
                <ParticipantFlow />
              </ProtectedRoute>
            }
          />
          <Route
            path="/thankyou"
            element={
              <ProtectedRoute
                authCheck={({ isParticipantAuthenticated }) =>
                  isParticipantAuthenticated
                }
              >
                <ThankYou />
              </ProtectedRoute>
            }
          />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
