import React from "react";
import { Navigate } from "react-router-dom";
import useAuthCheck from "../../hook/useAuth";

const ProtectedRoute = ({ children, authCheck }) => {
  const { isResearcherAuthenticated, isParticipantAuthenticated, isLoading } =
    useAuthCheck();

  if (isLoading) {
    return <div>Loading...</div>; // or any loading spinner you prefer
  }

  const isAuthenticated = authCheck({
    isResearcherAuthenticated,
    isParticipantAuthenticated,
  });

  return isAuthenticated ? children : <Navigate to="/" />;
};

export default ProtectedRoute;
