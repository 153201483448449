import { create } from "zustand";
import { persist } from "zustand/middleware";

const useUserStore = create(
  persist(
    (set, get) => ({
      email_id: "",
      invitation_code: "",
      role: "",
      setUser: (user) => set({ ...user }),
      clearUser: () => set({ email_id: "", invitation_code: "", role: "" }),
      getUser: () => {
        const state = get();
        return {
          email_id: state.email_id,
          invitation_code: state.invitation_code,
          role: state.role,
        };
      },
    }),
    {
      name: "user-storage",
    }
  )
);

export default useUserStore;
